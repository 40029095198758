import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Card,
  CardContent,
  CardActions,
  Button,
  Grid,
  TextField,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Pagination,
  IconButton,
  Tooltip
} from "@mui/material";
import { ApplicationURL,availableCategoriesURL, fetchWatchListURL } from "../../../src/apiEndpoints";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router-dom";
import { saveWatchListITEMURL,deleteWatchListITEMURL } from "../../../src/apiEndpoints";
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';

function AllEmployers() {
  const [companies, setCompanies] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const [selectedCategory, setSelectedCategory] = useState(location.state?.selectedCategory || "Technology");
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [companiesPerPage,setCompaniesPerPage] = useState(10);
  const [user,setUser]=useState(null);

  const companiesPerPageOptions=[10,20,50];
  const [watchlist, setWatchlist] = useState([]);



  const fetchWatchlist = useCallback((userId) => {
    fetch(fetchWatchListURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId: userId }),
    })
      .then((response) => response.json())
      .then((data) => {
        setWatchlist(data.watchList.map(item => ({
          companyId: item.companyId,
          watchId: item.watchId
        })));
      })
      .catch((error) => console.error("Error fetching watchlist: ", error));
  }, []);

  const removeFromWatchlist = useCallback(async (companyId) => {

    const watchItem = watchlist.find(item => item.companyId === companyId);
   
    if (!watchItem) {
      console.error("Watch item not found");
      return;
    }


    fetch(deleteWatchListITEMURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: user.userId,
        watchId: watchItem.watchId,
      }),
    })
      .then((response) => response.json())
      .then(() => {
        setWatchlist(prevWatchlist => prevWatchlist.filter(item => item.companyId !== companyId));
        console.log("Removed from watchlist successfully");
      })
      .catch((error) => console.error("Error removing from watchlist: ", error));
  },[user,watchlist]);


  useEffect(() => {
    const storedUser = sessionStorage.getItem('userInfo');
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUser(parsedUser);
      fetchWatchlist(parsedUser.userId)
    }
  }, [fetchWatchlist]);




  const addToWatchlist = useCallback( async(companyId) => {
    const currentDate = new Date().toISOString();
    fetch(saveWatchListITEMURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: user.userId, // Replace with actual user ID
        companyId: companyId,
        dateAdded: currentDate
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // You might want to show a success message here
        console.log("Added to watchlist successfully");
        setWatchlist(prev => [...prev, {companyId:data.companyId,watchId:data.watchId}]);
      })
      .catch((error) => console.error("Error adding to watchlist: ", error));
  },[user]);




  useEffect(() => {
    const fetchCategories = () => {
      fetch(availableCategoriesURL)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to fetch categories');
          }
          return response.json();
        })
        .then((data) => {
          let withAll = [...data];
          setCategories(withAll);
          // No need to set selectedCategory here as it's already set to "Technology"
        })
        .catch((error) => console.error("Error fetching categories: ", error));
    };
  
    fetchCategories();
  }, []);
  
  useEffect(() => {
    const fetchCompanies = () => {
      if (selectedCategory) {
        setIsLoading(true);
        const controller = new AbortController();  // Create an instance of AbortController
        const signal = controller.signal;  // Get the signal for the fetch request
  
        const url = selectedCategory === "All"
          ? `${ApplicationURL['public-url']}all/`
          : `${ApplicationURL['public-url']}${selectedCategory}/`;
  
        fetch(url, { signal })  // Pass the signal to the fetch request
          .then((response) => {
            if (!response.ok) {
              throw new Error('Failed to fetch companies');
            }
            return response.json();
          })
          .then((data) => {
            setCompanies(data.companies);
            setIsLoading(false);
          })
          .catch((error) => {
            if (error.name !== 'AbortError') {  // Ignore abort errors
              console.error("Error fetching companies: ", error);
              setIsLoading(false);
            }
          });
  
        return () => {
          controller.abort();  // Cleanup: Abort the fetch request if the component unmounts or `selectedCategory` changes
        };
      }
    };
  
    fetchCompanies();
  
  }, [selectedCategory]);

  const filteredCompanies = companies.filter((company) =>
    company.name.toLowerCase().includes(searchTerm.toLowerCase())
  );


  const getIndustryDisplay = (company) => {
    if (selectedCategory === "All") {
      return company.primaryIndustry;
    }


    return selectedCategory;
  };

  
  const pageCount = Math.ceil(filteredCompanies.length / companiesPerPage);
  const indexOfLastCompany = page * companiesPerPage;
  const indexOfFirstCompany = indexOfLastCompany - companiesPerPage;
  const currentCompanies = filteredCompanies.slice(indexOfFirstCompany, indexOfLastCompany);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          TOP OPT Employers in {selectedCategory}
        </Typography>
        <Box my={2}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Search Companies within this Category"
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Category</InputLabel>
                <Select
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  label="Category"
                >
                  {categories.map((category) => (
                    <MenuItem key={category} value={category}>
                      {category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={2}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Results:</InputLabel>
                <Select
                  value={companiesPerPage}
                  onChange={(e) => setCompaniesPerPage(e.target.value)}
                  label="Category"
                >
                  {companiesPerPageOptions.map((noofcompanies) => (
                    <MenuItem key={noofcompanies} value={noofcompanies}>
                      {noofcompanies}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography>
                Total Results: {filteredCompanies.length}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      <Grid container spacing={3}>
        {currentCompanies.map((company) => (
          <Grid item xs={12} sm={6} md={4} key={company.id}>
            <Card>
              <CardContent>
                <Typography variant="h6" component="div">
                  {company.name}
                </Typography>
               
                <Typography color="text.secondary">
                  Size: {company.size}+
                </Typography>
                <Typography color="text.secondary">
                  Industry: {getIndustryDisplay(company)}
                </Typography>
                
              </CardContent>
              <CardActions>
                {company.careersPage && (
                  <Button
                    size="small"
                    color="primary"
                    href={company.careersPage}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Career Page
                  </Button>
                )}
              {(
                  watchlist.find((item)=>item.companyId===company.id) ? (
                    <Tooltip title="Already in Watchlist">
                      <IconButton onClick={()=>removeFromWatchlist(company.id)}>
                        <BookmarkAddedIcon/>
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Add to Watchlist">
                      <IconButton onClick={() => addToWatchlist(company.id)}>
                       <BookmarkAddIcon/>
                      </IconButton>
                    </Tooltip>
                  )
                )}
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
        <Box mt={4} display="flex" justifyContent="center">
          <Pagination 
            count={pageCount} 
            page={page} 
            onChange={handlePageChange} 
            color="primary" 
          />
        </Box>
      </Box>
    </Container>
  );
}

export default AllEmployers;