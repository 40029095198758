
// SearchByName.js
import React from 'react';
import { Card, CardContent, Typography, Grid, Box, Pagination } from '@mui/material';

const SearchByName = ({ results, showDetails, page, totalPages, onPageChange, itemsPerPage }) => {
  if (!results || !results.companies || results.companies.length === 0) {
    return <Typography>No matching employers found</Typography>;
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Matching Employers: {results.totalItems}
      </Typography>
      <Grid container spacing={3}>
        {results.companies.map((company, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              <CardContent>
                <Typography variant="h6" component="div">
                  {company.name}
                </Typography>
                <Typography color="text.secondary">
                  Workforce Size: {company.size}
                </Typography>
                {showDetails && (
                  <Box mt={2}>
                    {Object.entries(company).map(([key, value]) => (
                      <Typography key={key} variant="body2">
                        <strong>{key}:</strong> {value}
                      </Typography>
                    ))}
                  </Box>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Pagination 
          count={totalPages} 
          page={page} 
          onChange={(event, value) => onPageChange(event, value)}
        />
      </Box>
    </Box>
  );
}

export default SearchByName;