// EmployerSearch.js
import React, { useState, useEffect } from "react";
import { TextField, Checkbox, FormControlLabel, Box, Typography, Button, CircularProgress, Select, MenuItem } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import SearchByName from "./SearchByName";
import { GLOBALEMPLOYERSEARCH } from "../../apiEndpoints";

export function EmployerSearch() {
  const [employerName, setEmployerName] = useState("");
  const [showDetails, setShowDetails] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchInitiated, setSearchInitiated] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const handleShowDetails = (event) => {
    setShowDetails(event.target.checked);
  };

  const handleInputChange = (event) => {
    if(!event.target.value){
      setSearchResults([]);
    }
    setEmployerName(event.target.value);
  };

  const handleSearch = async () => {
    if (employerName.trim().length > 1) {
      setSearchInitiated(true);
      setLoading(true);

      try {
        const response = await fetch(`${GLOBALEMPLOYERSEARCH}?searchString=${employerName}&page=${page}&size=${itemsPerPage}`);
        const data = await response.json();
        setSearchResults(data);
        setTotalPages(data.totalPages);
      } catch (error) {
        console.error('Error fetching companies:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
    setPage(1);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  useEffect(() => {
    if (searchInitiated) {
      handleSearch();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, itemsPerPage,searchInitiated]);

  return (
    <Box sx={{ maxWidth: 600, margin: "auto", mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        OPT Employer Search
      </Typography>
      
      <Box display="flex" mb={2}>
        <TextField
          fullWidth
          label="Search An Employer"
          variant="outlined"
          value={employerName}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
        />
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleSearch}
          sx={{ ml: 1 }}
          startIcon={<SearchIcon />}
          disabled={employerName.trim().length <= 1 || loading}
        >
          Search
        </Button>
      </Box>

      <Box display="flex" alignItems="center" mb={2}>
        <FormControlLabel
          control={<Checkbox checked={showDetails} onChange={handleShowDetails} />}
          label="View Full Detail"
        />
        <Box ml={2}>
          <Select
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </Box>
      </Box>
      
      {loading && (
        <Box display="flex" justifyContent="center" mt={4}>
          <CircularProgress />
        </Box>
      )}

      {searchInitiated && !loading && (
        <SearchByName 
          results={searchResults}
          showDetails={showDetails}
          page={page}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          itemsPerPage={itemsPerPage}
        />
      )}

    </Box>

  );
}