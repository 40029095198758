export const ApplicationURL = {
    'public-url': 'https://api.test.opt.glancejobs.com/company/',
    'local-url': 'http://localhost:8080/company/'
  }

export const GLOBALEMPLOYERSEARCH= "https://api.test.opt.glancejobs.com/allcompany/search";
  
export const CompanyIngestionReqURL = {
  'public-url': 'https://api.test.opt.glancejobs.com/companyrequest/',
  'local-url': 'http://localhost:8080/companyrequest/'
}

export const categoryCountURL="https://api.test.opt.glancejobs.com/company/avc-count/";

export const topEmployerSearch="https://api.test.opt.glancejobs.com/company/search?searchString";

export const availableCategoriesURL="https://api.test.opt.glancejobs.com/company/available-categories/";

export const newCompanyIngestionRequest="https://api.test.opt.glancejobs.com/companyrequest/newCompanyRequest/";

export const fetchWatchListURL="https://api.test.opt.glancejobs.com/watchlist/"

export const deleteWatchListITEMURL="https://api.test.opt.glancejobs.com/watchlist/delete"

export const saveWatchListITEMURL="https://api.test.opt.glancejobs.com/watchlist/save"