// Components/Auth/SignOut.js
import React from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import {app} from './firebase'

function SignOut() {
  const navigate = useNavigate();
  const auth = getAuth(app);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      console.log("User signed out successfully");
      sessionStorage.removeItem('userInfo');
      sessionStorage.removeItem('firebaseToken');
      navigate('/login'); // Redirect to login page after sign out
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <div>
      <h2>Sign Out</h2>
      <p>Are you sure you want to sign out?</p>
      <button onClick={handleSignOut}>Yes, Sign Out</button>
    </div>
  );
}

export default SignOut;