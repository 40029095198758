import React, { useState, useEffect,useCallback } from 'react';
import { 
  Container, 
  Grid, 
  Card, 
  CardContent, 
  Typography, 
  CardActionArea,
  Pagination,
  Box,
  Chip,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Tab,
  Button,
  Tabs,
  Tooltip,
  IconButton
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { categoryCountURL } from './apiEndpoints';
import { topEmployerSearch,saveWatchListITEMURL,fetchWatchListURL,deleteWatchListITEMURL } from './apiEndpoints';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';

function HomePage() {
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [employers, setEmployers] = useState([]);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchType, setSearchType] = useState('categories');
  const navigate = useNavigate();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [watchlist, setWatchlist] = useState([]);
  const [user,setUser]=useState(null);


  const fetchWatchlist = useCallback((userId) => {
    fetch(fetchWatchListURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId: userId }),
    })
      .then((response) => response.json())
      .then((data) => {
        setWatchlist(data.watchList.map(item => ({
          companyId: item.companyId,
          watchId: item.watchId
        })));
      })
      .catch((error) => console.error("Error fetching watchlist: ", error));
  }, []);

  const removeFromWatchlist = useCallback(async (companyId) => {

    const watchItem = watchlist.find(item => item.companyId === companyId);
   
    if (!watchItem) {
      console.error("Watch item not found");
      return;
    }


    fetch(deleteWatchListITEMURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: user.userId,
        watchId: watchItem.watchId,
      }),
    })
      .then((response) => response.json())
      .then(() => {
        setWatchlist(prevWatchlist => prevWatchlist.filter(item => item.companyId !== companyId));
        console.log("Removed from watchlist successfully");
      })
      .catch((error) => console.error("Error removing from watchlist: ", error));
  },[user,watchlist]);


  useEffect(() => {
    const storedUser = sessionStorage.getItem('userInfo');
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUser(parsedUser);
      fetchWatchlist(parsedUser.userId)
    }
  }, [fetchWatchlist]);




  const addToWatchlist = useCallback( async(companyId) => {

    const currentDate = new Date().toISOString(); 
    fetch(saveWatchListITEMURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: user.userId, // Replace with actual user ID
        companyId: companyId,
        dateAdded: currentDate
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // You might want to show a success message here
        console.log("Added to watchlist successfully");
        setWatchlist(prev => [...prev, {companyId:data.companyId,watchId:data.watchId}]);
      })
      .catch((error) => console.error("Error adding to watchlist: ", error));
  },[user,watchlist]);


  useEffect(() => {
    document.title = 'optglancejobs'
    setLoading(true);
    fetch(categoryCountURL)
      .then(response => response.json())
      .then(data => {
        setCategories(data);
        setFilteredCategories(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
        setLoading(false);
      });
  }, []);


  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    },1000);

    return () => clearTimeout(timer);
  }, [searchTerm]);

  useEffect(() => {
    if (searchType === 'categories') {
      const results = categories.filter(category =>
        category.category.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
      );
      setFilteredCategories(results);
    } else {
      if (debouncedSearchTerm.length > 2 ) {
        setLoading(true);
        fetch(`${topEmployerSearch}=${debouncedSearchTerm}`)
          .then(response => response.json())
          .then(data => {
            setEmployers(data.companies || []);
            setLoading(false);
          })
          .catch(error => {
            console.error('Error fetching employers:', error);
            setLoading(false);
          });
      } else {
        setEmployers([]);
      }
    }
    setPage(1);
  }, [debouncedSearchTerm, categories, searchType]);

  const handleCardClick = (item) => {
    if (searchType === 'categories') {
      navigate('/all-employers', { state: { selectedCategory: item.category } });
    } else {
      navigate('/company-details', { state: { selectedCompany: item } });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeItemsPerPage = (event) => {
    setItemsPerPage(event.target.value);
    setPage(1);
  };

  const handleSearchChange = (event) => {
    
    setSearchTerm(event.target.value);
  };

  const handleSearchTypeChange = (event, newValue) => {
    setSearchType(newValue);
    setSearchTerm('');
  };

  // Calculate the items to display on the current page
  const indexOfLastItem = page * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = searchType === 'categories' 
    ? filteredCategories.slice(indexOfFirstItem, indexOfLastItem)
    : employers.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate total number of pages
  const pageCount = Math.ceil((searchType === 'categories' ? filteredCategories.length : employers.length) / itemsPerPage);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" component="h1" gutterBottom align="center" style={{ marginTop: '2rem' }}>
        TOP OPT Employers
      </Typography>
      <Box mb={2}>
        <Tabs value={searchType} onChange={handleSearchTypeChange} centered>
          <Tab label="Search Categories" value="categories" />
          <Tab label="Search Employers" value="employers" />
        </Tabs>
      </Box>
      <Box display="flex" justifyContent="space-around" alignItems="center" mb={2}>
        <TextField
          label={searchType === 'categories' ? "Search Categories" : "Search Employers"}
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          autoFocus={searchType !== 'categories'} 
          sx={{flex:"0 0 80%"}}
          style={ searchType === 'categories'? {minWidth: 200}:{maxWidth:true }}
        />
        <FormControl variant="outlined" style={{ minWidth: 120 }}>
          <InputLabel>Per Page</InputLabel>
          <Select
            value={itemsPerPage}
            onChange={handleChangeItemsPerPage}
            label="Per Page"
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={30}>30</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {currentItems.length === 0 && debouncedSearchTerm.length>2 ? (
        <Typography variant="h6" align="center">No results found.</Typography>
      ) : (
        <>
          <Grid container spacing={3}>
            {currentItems.map((item) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={searchType === 'categories' ? item.category : item.id}>
                <Card>
                <CardActionArea onClick={searchType === "categories" ? () => handleCardClick(item) : undefined}>



                    <CardContent>
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6" component="div" style={{ flexGrow: 1, marginRight: '8px' }}>
                          {searchType === 'categories' ? item.category : item.name}
                        </Typography>
                        {searchType === 'categories' ? (
                          <Chip
                            label={item.count}
                            color="primary"
                            size="small"
                            style={{
                              fontWeight: 'bold',
                              minWidth: '40px',
                            }}
                          />
                        ) : (
                          <Typography variant="body2" color="text.secondary">
                            {item.primaryIndustry}
                          </Typography>
                          
                        )}
                      </Box>
                      {searchType === 'employers' && (
                        <>
                         
                          <Typography variant="body2" color="text.secondary">
                            {/* State: {item.state} */}
                          </Typography>
                           <Typography variant="body2" color="text.secondary">
                            Size: {item.size}+
                          </Typography>

                        </>
                        
                      )}
                       
                {item.careersPage && (
                  <Button
                    size="small"
                    color="primary"
                    href={item.careersPage}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Career Page
                  </Button>
                  
                )}

                    </CardContent>
                    {item.name && (    
                  watchlist.find((_it)=>_it.companyId===item.id) ? (
                    <Tooltip title="Already in Watchlist">
                      <IconButton onClick={()=>removeFromWatchlist(item.id)}>
                        <BookmarkAddedIcon/>
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Add to Watchlist">
                    <IconButton onClick={() => addToWatchlist(item.id)}>
                     <BookmarkAddIcon/>
                    </IconButton>
                  </Tooltip>
                  )
                )}
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Box display="flex" justifyContent="center" mt={4}>
            <Pagination 
              count={pageCount} 
              page={page} 
              onChange={handleChangePage} 
              color="primary"
            />
          </Box>
        </>
      )}
    </Container>
  );
}

export default HomePage;