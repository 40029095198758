import React, { useEffect, useState } from 'react';

const WebViewDetector = () => {
  const [isWebView, setIsWebView] = useState(false);

  useEffect(() => {
    const detectWebView = () => {
      const standalone = window.navigator.standalone;
      const userAgent = window.navigator.userAgent.toLowerCase();
      const safari = /safari/.test(userAgent);
      const ios = /iphone|ipod|ipad/.test(userAgent);

      if (ios) {
        if (!standalone && safari) {
          return false;
        } else if (!standalone && !safari) {
          return true;
        }
      } else {
        if (userAgent.includes('wv')) {
          return true;
        }
      }
      return false;
    };

    setIsWebView(detectWebView());
  }, []);

  useEffect(() => {
    if (isWebView) {
      alert("For the best experience, please open this link in your device's default browser.");
    }
  }, [isWebView]);

  return null; // This component doesn't render anything
};

export default WebViewDetector;