import React, { useState, useEffect,useCallback } from 'react';
import axios from 'axios';
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';

import { CompanyIngestionReqURL } from './apiEndpoints';
import { availableCategoriesURL,newCompanyIngestionRequest } from './apiEndpoints';


const CompanyRequestForm = () => {
  const [user, setUser] = useState(null);
  const [companyRequests, setCompanyRequests] = useState([]);
  const [currentRequest, setCurrentRequest] = useState({
    name: '',
    size: '',
    primaryIndustry: '',
    secondary: '',
    state: '',
    country: '',
    careersPage: '',
    companyLogoUrl: '',
    hqState: '',
    hqCity: '',
  });
  const [editingId, setEditingId] = useState(null);
  const [industries, setIndustries] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');


  useEffect(() => {
    const storedUser = sessionStorage.getItem('userInfo');
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUser(parsedUser);
    }

    fetchIndustries();
  }, []);

  const fetchUserRequests = useCallback(async (userId) => {
    try {
      const response = await axios.post(`${CompanyIngestionReqURL['public-url']}user/`, { userId: userId });
      setCompanyRequests(response.data);
    } catch (error) {
      console.error('Error fetching user requests', error);
    }
  },[]);


  
  useEffect(() => {
    if (user && user.userId) {
      fetchUserRequests(user.userId);
    }
  }, [user,fetchUserRequests]);





  

  const fetchIndustries = async () => {
    try {
      const response = await axios.get(availableCategoriesURL);
      setIndustries(response.data);
    } catch (error) {
      console.error('Error fetching industries', error);
    }
  };


  const handleChange = (e) => {
    setCurrentRequest({ ...currentRequest, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const requestData = { ...currentRequest, userId: user.userId };
      if (editingId) {
        await axios.put(`${CompanyIngestionReqURL['public-url']}`, requestData);
        setSnackbarMessage("Company request updated successfully");
      } else {
        await axios.post(newCompanyIngestionRequest, requestData);
        setSnackbarMessage("Company request created successfully");
      }
      setSnackbarSeverity("success");
      setOpenSnackbar(true);
      resetForm();
      fetchUserRequests(user.userId);
    } catch (error) {
      console.error('Error submitting company request', error);
      setSnackbarMessage("Error submitting company request");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
  };

  const handleEdit = (request) => {
    setCurrentRequest(request);
    setEditingId(request.id);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${CompanyIngestionReqURL['public-url']}${id}/`);
      setSnackbarMessage("Company request deleted successfully");
      setSnackbarSeverity("success");
      setOpenSnackbar(true);
      fetchUserRequests(user.userId);
    } catch (error) {
      console.error('Error deleting company request', error);
      setSnackbarMessage("Error deleting company request");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
  };

  const resetForm = () => {
    setCurrentRequest({
      name: '',
      size: '',
      primaryIndustry: '',
      secondary: '',
      state: '',
      country: '',
      careersPage: '',
      companyLogoUrl: '',
      hqState: '',
      hqCity: '',
    });
    setEditingId(null);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  if (!user) {
    return <Typography>Please log in to submit company requests.</Typography>;
  }

  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" component="h2" gutterBottom>
          {editingId ? 'Edit Company Request' : 'Create New Company Request'}
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            name="name"
            label="Name"
            value={currentRequest.name}
            onChange={handleChange}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            name="size"
            label="Size"
            value={currentRequest.size}
            onChange={handleChange}
            type='number'
            required
          />
          <FormControl fullWidth margin="normal" required>
            <InputLabel id="primary-industry-label">Primary Industry</InputLabel>
            <Select
              labelId="primary-industry-label"
              name="primaryIndustry"
              value={currentRequest.primaryIndustry}
              onChange={handleChange}
              label="Primary Industry"
            >
              {industries.map((industry) => (
                <MenuItem key={industry} value={industry}>
                  {industry}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            margin="normal"
            name="secondary"
            label="Secondary Industry"
            value={currentRequest.secondary}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="normal"
            name="state"
            label="State"
            value={currentRequest.state}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="normal"
            name="country"
            label="Country"
            value={currentRequest.country}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="normal"
            name="careersPage"
            label="Careers Page URL"
            value={currentRequest.careersPage}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="normal"
            name="companyLogoUrl"
            label="Company Logo URL"
            value={currentRequest.companyLogoUrl}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="normal"
            name="hqState"
            label="HQ State"
            value={currentRequest.hqState}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="normal"
            name="hqCity"
            label="HQ City"
            value={currentRequest.hqCity}
            onChange={handleChange}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
          >
            {editingId ? 'Update Request' : 'Submit Request'}
          </Button>
          {editingId && (
            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              sx={{ mt: 1 }}
              onClick={resetForm}
            >
              Cancel Edit
            </Button>
          )}
        </form>

        <Typography variant="h5" component="h3" gutterBottom sx={{ mt: 4 }}>
          Your Company Requests
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Primary Industry</TableCell>
                <TableCell>Size</TableCell>
                <TableCell>State</TableCell>
                <TableCell>Country</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companyRequests.map((request) => (
                <TableRow key={request.id}>
                  <TableCell>{request.name}</TableCell>
                  <TableCell>{request.primaryIndustry}</TableCell>
                  <TableCell>{request.size}</TableCell>
                  <TableCell>{request.state}</TableCell>
                  <TableCell>{request.country}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleEdit(request)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(request.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  );
};

export default CompanyRequestForm;