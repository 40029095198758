// src/Components/Auth/Login.js
import React, { useEffect, useState } from 'react';
import { signInWithPopup, GoogleAuthProvider, getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail,sendEmailVerification } from 'firebase/auth';import { useNavigate, useLocation, Link } from 'react-router-dom';
import { app } from './firebase'; // Adjust this import path as needed
import { Button, Typography, Paper, TextField, styled, Switch, FormControlLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,Snackbar, Alert } from '@mui/material';
import { blockedDomains } from './blockerDomains';
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: 400,
  margin: 'auto',
  marginTop: theme.spacing(8),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: 'white',
  color: 'black',
  '&:hover': {
    backgroundColor: theme.palette.grey[100],
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
  },
}));



function Login() {
  const navigate = useNavigate();
  const auth = getAuth(app);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLogin, setIsLogin] = useState(true);
  const [openResetDialog, setOpenResetDialog] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [resetMessage, setResetMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');



  useEffect(()=>{
    document.title = 'Login'
  },[])

  const sendVerificationEmail = async (user) => {
    const actionCodeSettings = {
      url: `${window.location.origin}/verify-email`, // Uses the current domain
      handleCodeInApp: true,
    };

    try {
      await sendEmailVerification(user, actionCodeSettings);
      setSnackbarMessage('Verification link sent. Please check your email and click the link to verify your account.');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error sending verification email:', error);
      setSnackbarMessage('Error sending verification email. Please try again later. after few minutes');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };


  const handleResetPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, resetEmail);
      setResetMessage('Password reset email sent. Check your inbox.');
    } catch (error) {
      setResetMessage(`Error: ${error.message}`);
    }
  };


  const signInWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
       const result = await signInWithPopup(auth, provider);
       const user = result.user;
       const idToken = await user.getIdToken();

       const userInfo={
        userId:user.uid,
        displayName:user.displayName,
        email:user.email,
        photoURL:user.photoURL
       }
    
       // Store the token securely (e.g., in memory or a secure cookie)
       // Do NOT store in localStorage as it's not secure
       sessionStorage.setItem('firebaseToken', idToken);
       sessionStorage.setItem('userInfo',JSON.stringify(userInfo))

      navigate('/');  // Redirect to home page after successful login
    } catch (error) {
      console.error("Error signing in with Google", error);
      setError(error.message);
    }
  };

  const handleEmailPasswordAction = async (e) => {
    e.preventDefault();
    setError('');

    try {
      if (isLogin) {
        // Login logic
        const result = await signInWithEmailAndPassword(auth, email, password);
        const user = result.user;
        
        if (!user.emailVerified) {
          setSnackbarMessage('Your email is not verified. Please verify your email to log in.');
          setSnackbarSeverity('warning');
          await sendVerificationEmail(user);
          setOpenSnackbar(true);
          return;
        }
        
        // Proceed with login
        const idToken = await user.getIdToken();
        const userInfo = {
          userId: user.uid,
          displayName: user.displayName,
          email: user.email,
          photoURL: user.photoURL
        };
        sessionStorage.setItem('firebaseToken', idToken);
        sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
        navigate('/');
      } else {
        // Registration logic
        if (password.length < 8) {
          throw new Error('Password must be at least 8 characters long');
        }
        
        const emailDomain = email.split('@')[1];
        if (blockedDomains.includes(emailDomain)) {
          throw new Error('Registration not allowed with this email domain');
        }
        
        // Create user account
        const result = await createUserWithEmailAndPassword(auth, email, password);
        const user = result.user;
        
        // Send verification email
        await sendEmailVerification(user);
        
        // Update UI
        setSnackbarMessage('Verification email sent. Please check your inbox and verify your email before logging in.');
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
        setEmail('');
        setPassword('');
        
        // Optionally, sign out the user to force email verification
        await auth.signOut();
      }
    } catch (error) {
      console.error(`Error ${isLogin ? 'signing in' : 'registering'} with email/password`, error);
      setSnackbarMessage(error.message.replace('Firebase:'," "));
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    } 
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  
  return (
    <StyledPaper elevation={3}>
      <Typography variant="h4" component="h1" gutterBottom>
        {isLogin ? 'Login' : 'Register'}
      </Typography>
      <FormControlLabel
        control={<Switch checked={!isLogin} onChange={() => setIsLogin(!isLogin)} />}
        label={isLogin ? "Need to register?" : "Already have an account?"}
      />
      <form onSubmit={handleEmailPasswordAction} style={{ width: '100%' }}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <StyledButton
          type="submit"
          fullWidth
          variant="contained"
        >
          {isLogin ? 'Sign In' : 'Register'}
        </StyledButton>
      </form>
      {isLogin && (
        <Typography variant="body2" style={{ marginTop: '1rem', cursor: 'pointer', color: 'blue' }} onClick={() => setOpenResetDialog(true)}>
          Forgot Password?
        </Typography>
      )}
      <Typography variant="body2" style={{ marginTop: '1rem' }}>
        Or
      </Typography>
      <StyledButton
        fullWidth
        variant="contained"
        onClick={signInWithGoogle}
        startIcon={<img src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" alt="Google logo" width="18" height="18" />}
      >
        Sign In with Google
      </StyledButton>
      {error && (
        <Typography color="error" style={{ marginTop: '1rem' }}>
          {error.split(":")}
        </Typography>
      )}
   <Dialog open={openResetDialog} onClose={() => setOpenResetDialog(false)}>
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter your email address and we'll send you a link to reset your password.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="resetEmail"
            label="Email Address"
            type="email"
            fullWidth
            variant="outlined"
            value={resetEmail}
            onChange={(e) => setResetEmail(e.target.value)}
          />
          {resetMessage && (
            <Typography color={resetMessage.includes('Error') ? 'error' : 'primary'}>
              {resetMessage}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenResetDialog(false)}>Cancel</Button>
          <Button onClick={handleResetPassword}>Send Reset Email</Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>  
    </StyledPaper>
  );
}

export default Login;