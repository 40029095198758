import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      
      <h2>1. Introduction</h2>
      <p>This privacy policy explains how glancejobsopt collects, uses, and protects your personal information when you use our application.</p>
      
      <h2>2. Information Collected</h2>
      <p>We collect information you provide when you sign up and use our service, including but not limited to:</p>
      <ul>
        <li>Name</li>
        <li>Email address</li>
        <li>Profile information from Google</li>
      </ul>
      
      <h2>3. How Information is Used</h2>
      <p>We use your information to:</p>
      <ul>
        <li>Provide and maintain our service</li>
        <li>Improve and personalize user experience</li>
        <li>Communicate with you about our service</li>
      </ul>
      
      <h2>4. Data Storage and Security</h2>
      <p>We securely store and manage user authentication data. We implement industry-standard security measures to protect your information.</p>
      
      <h2>5. Third-party Services</h2>
      <p>We use Google Sign-In for authentication. Please refer to Google's privacy policy for information on how they handle your data.</p>
      
      <h2>6. User Rights and Choices</h2>
      <p>You have the right to access, correct, or delete your personal information. You can also opt-out of certain data collection or use.</p>
      
      <h2>7. Updates to the Policy</h2>
      <p>We may update this policy from time to time. We will notify you of any significant changes.</p>
      
      <h2>8. Contact Information</h2>
      <p>If you have any questions about this privacy policy, please contact us at optglancejobs@gmail.com</p>
    </div>
  );
};

export default PrivacyPolicy;