import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="max-w-2xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Privacy Policy for GlanceJobs LinkedIn Company Checker</h1>
      <p className="mb-4">Last updated: Oct 5 2024</p>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Introduction</h2>
        <p>
          This Privacy Policy describes how GlanceJobs LinkedIn Company Checker ("we", "our", or "us") 
          collects, uses, and shares information when you use our browser extension.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Information We Collect</h2>
        <p>Our extension collects the following information:</p>
        <ul className="list-disc pl-5 mb-2">
          <li>
            Company names from the LinkedIn jobs section to flag OPT and Non OPT employers that accept EAD.
          </li>
        </ul>
        <p>We do not collect any other personal information or data from your browsing activities.</p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">How We Use the Information</h2>
        <p>
          The company names collected are used to check against our database to determine if the 
          employer supports Optional Practical Training (OPT) and Employment Authorization Document 
          (EAD) cards for international students. This information is processed within the extension 
          to provide you with relevant information about potential employers.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Data Storage and Security</h2>
        <p>
          The company names collected by the extension are temporarily stored locally on your device 
          for the duration of your browsing session. We also send these company names to our server 
          to check against our database of OPT employers.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Third-Party Services</h2>
        <p>
          Our extension interacts with LinkedIn's website to collect company names. Please note that 
          this Privacy Policy does not cover the practices of LinkedIn or any other third-party 
          websites you may visit using our extension.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Changes to Our Privacy Policy</h2>
        <p>
          We may update this privacy policy from time to time. We will notify you of any changes by 
          posting the new privacy policy on this page and updating the "Last updated" date.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us at optglancejobs@gmail.com.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;