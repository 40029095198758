import React, { useState, useEffect } from "react";
import { HashRouter as BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { AppBar, Toolbar, Box, styled } from "@mui/material";
import "./App.css";
import ExtensionPrivacy from "./static/ExtensionPrivacy";
import AllEmployers from "./Components/LargeEmployers/AllEmployers";
import { EmployerSearch } from "./Components/search/EmployerSearch";
import NavigationTabs from "./Navigation/NavigationTabs";
import AboutPage from "./static/About";
import PrivacyPolicy from "./static/PrivacyPolicy";
import { useNavigate } from 'react-router-dom';
import SignOut from "./SignOut";
import Login from "./Login";
import Profile from "./Profile";
import ExtensionInfoPage from "./static/Extenstion";

import { app } from './firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import HomePage from "./Homepage";
import CompanyRequestForm from "./CompanyRequestForm";
import WatchList from "./Components/WatchList";



const WhiteAppBar = styled(AppBar)({
  backgroundColor: 'white',
  color: 'black',
  boxShadow: 'none',
  borderBottom: '1px solid #e0e0e0',
});

const auth = getAuth(app);


const ProtectedRoute = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      // setUser(user);
      // setLoading(false);
      const storedUser = sessionStorage.getItem('userInfo');
      const _pareseduser=JSON.parse(storedUser);

      if(_pareseduser && user ){
      if(_pareseduser.userId===user.uid){
      if (storedUser) {
      setUser(JSON.parse(storedUser));
       setLoading(false);
       }
  }
  }
  else{
    setUser(null)
    setLoading(false);
    navigate('/login')
  }

    });
    

    return () => unsubscribe();
  }, [navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const auth = getAuth(app);
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  return (
    <BrowserRouter>
      <Box sx={{ flexGrow: 1 }}>
        <WhiteAppBar position="static">
          <Toolbar>
            <NavigationTabs user={user} />
          </Toolbar>
        </WhiteAppBar>
        
        <Box sx={{ padding: 3 }}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/extension-privacy-policy" element={<ExtensionPrivacy />} />
            <Route path="/extension" element={<ExtensionInfoPage />}/>
            <Route path="/signout" element={<SignOut />} />
            
            <Route path="/" element={
              <ProtectedRoute>
                <HomePage />
              </ProtectedRoute>
            } />
            <Route path="/all-employers" element={
              <ProtectedRoute>
                <AllEmployers />
              </ProtectedRoute>
            } />
            <Route path="/All" element={
              <ProtectedRoute>
                <EmployerSearch />
              </ProtectedRoute>
            } />
            <Route path="/company-requests" element={
            <ProtectedRoute>
              <CompanyRequestForm />
            </ProtectedRoute>
          } />
            <Route path="/profile" element={
              <ProtectedRoute>
                <Profile />
                </ProtectedRoute>} />

                <Route path="/watchlist" element={
              <ProtectedRoute>
                <WatchList />
                </ProtectedRoute>} />
          </Routes>

        </Box>
      </Box>
    </BrowserRouter>
  );
}

export default App;