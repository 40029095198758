import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Typography,
  Box,
  Paper,
  Button,
  Avatar,
  Grid,
  Divider
} from '@mui/material';
import { Person as PersonIcon, Business as BusinessIcon } from '@mui/icons-material';

const Profile = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedUser = sessionStorage.getItem('userInfo');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  if (!user) {
    return <Typography>Please log in to view your profile.</Typography>;
  }

  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <Avatar sx={{ width: 100, height: 100, bgcolor: 'primary.main' }}>
                <PersonIcon sx={{ fontSize: 60 }} />
              </Avatar>
            </Grid>
            <Grid item xs>
              <Typography variant="h4" gutterBottom>
                {user.displayName || 'User Profile'}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                User ID: {user.userId}
              </Typography>
            </Grid>
          </Grid>
          
          <Divider sx={{ my: 3 }} />
          
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" gutterBottom>
                Email
              </Typography>
              <Typography>{user.email || 'Not provided'}</Typography>
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <Typography variant="h6" gutterBottom>
                Role
              </Typography>
              <Typography>{user.role || 'Regular User'}</Typography>
            </Grid> */}
            {/* Add more user details as needed */}
          </Grid>
        </Paper>
        <Paper elevation={3} sx={{ p: 3 ,marginTop:2}}>
          <Typography variant="h5" gutterBottom>
            Request Section
          </Typography>
          <Typography variant="body1" paragraph>
            Use this section to request adding a new company to our database.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<BusinessIcon />}
            component={Link}
            to="/company-requests"
          >
            Request to Add Company
          </Button>
        </Paper>
      </Box>
    </Container>
  );
};

export default Profile;