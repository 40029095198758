export const blockedDomains = [
    // Temporary email services
    "10minutemail.com",
    "disposablemail.com",
    "guerrillamail.com",
    "mailinator.com",
    "temp-mail.org",
    "fakeinbox.com",
    "tempmail.net",
    "throwawaymail.com",
    "yopmail.com",
    "nada.email",
    "sharklasers.com",
    "guerrillamail.info",
    "grr.la",
    "maildrop.cc",
  
    // Disposable email services
    "temp-mail.io",
    "dispostable.com",
    "mailnesia.com",
    "mailcatch.com",
    "tempmailaddress.com",
    "tmpmail.net",
    "tempail.com",
    "eelmail.com",
    "cs.email",
    "emlpro.com",
    "mail-temp.com",
  
    // Free email services often associated with spam (use cautiously)
    "mail.ru",
    "inbox.ru",
    "list.ru",
    "bk.ru",
    "yandex.ru",
    "ya.ru",
  
    // Other commonly abused domains
    "example.com",
    "test.com",
    "email.com",
    "spam.com",
    "spam4.me",
    "epochmail.com",
    "letmail.net",
    "putmail.com",
    "getnada.com",
    "abcmail.email",
    "luxusmail.org",
    "zzrgg.com",
    "12minutemail.com",
    "12houremail.com",
    "1ce.us",
    "24hourmail.com",
    "anonbox.net",
    "click-mail.net",
    "crazymailing.com",
    "deadaddress.com",
    "e4ward.com",
    "emailtemporanea.net",
    "emailtemporario.com.br",
    "evopo.com",
    "getairmail.com",
    "haltospam.com",
    "incognitomail.com",
    "jetable.org",
    "look4mail.com",
    "mailexpire.com",
    "mailhazard.com",
    "mailimate.com",
    "mailmoat.com",
    "mytrashmail.com",
    "neutromail.com",
    "noclickemail.com",
    "notsharingmy.info",
    "otherinbox.com",
    "punkass.com",
    "quickinbox.com",
    "recode.me",
    "safetymail.info",
    "spambox.us",
    "spamfree24.org",
    "spamgourmet.com",
    "spamhole.com",
    "spaml.com",
    "tempemail.net",
    "temporaryinbox.com",
    "trashmail.net",
    "veryrealemail.com",
    "webm4il.info",
    "wegwerfemail.de",
    "wh4f.org",
    "zippymail.info",
    "jnxjn.com",
    "trashmail.com",
    "mailmetrash.com",
    "mt2015.com",
    "mt2014.com",
    "thankyou2010.com",
    "trash2009.com",
    "mt2009.com",
    "trashymail.com",
    "tempomail.fr",
    "dedmail.com",
    "armyspy.com",
    "cuvox.de",
    "dayrep.com",
    "einrot.com",
    "fleckens.hu",
    "gustr.com",
    "jourrapide.com",
    "rhyta.com",
    "superrito.com",
    "teleworm.us",
  ];