import React from 'react';
import { Link } from 'react-router-dom';

const AboutPage = () => {
  return (
    <div className="about-page">
      <h1>About opt.glancejobs.com</h1>
      
      <section>
        <p>
          This application provides a platform where international students can:
        </p>
        <ul>
          <li>Search for job listings from companies that accept EAD cards</li>
          <li>Filter opportunities based on their specific visa status</li>
          <li>Access resources and information about the job search process for EAD holders</li>
          </ul>
      </section>
      

      
      <section>
        <h2>Privacy and Security</h2>
        <p>
          We understand the importance of protecting your personal information. To learn more 
          about how we handle your data, please read our {' '}
          <Link to="/privacy-policy">Privacy Policy</Link>.
        </p>

        <p>
          For Extension Privacy policy please follow
          <Link to="/extension-privacy-policy"> Extention Privacy Policy</Link>.
        </p>
      </section>
      
      <section>
        <h2>Contact Us</h2>
        <p>
          If you have any questions or feedback about our service, please don't hesitate to 
          reach out to us at <a href="mailto:optglancejobs@gmail.com"> optglancejobs@gmail.com</a>.
        </p>
      </section>
    </div>
  );
};

export default AboutPage;