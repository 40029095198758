import React, { useState, useEffect, useCallback } from "react";
import {
  Box, Card, CardContent, CardActions, Button, Grid,
  Container, IconButton, Tooltip, Typography, Paper,
  TextField, Select, MenuItem, InputLabel, FormControl,Pagination
} from "@mui/material";
import { Delete, InfoOutlined, Search } from "@mui/icons-material";
import { fetchWatchListURL, deleteWatchListITEMURL } from "../apiEndpoints";
import { Link } from "react-router-dom";

function WatchList() {
  const [watchlist, setWatchlist] = useState([]);
  const [filteredWatchlist, setFilteredWatchlist] = useState([]);
  const [user, setUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("desc");
  const [page, setPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const fetchWatchlist = useCallback(async (userId) => {
    try {
      const response = await fetch(fetchWatchListURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId: userId }),
      });
      const data = await response.json();
      setWatchlist(data.watchList);
      setFilteredWatchlist(data.watchList);
    } catch (error) {
      console.error("Error fetching watchlist: ", error);
    }
  }, []);

  useEffect(() => {
    const storedUser = sessionStorage.getItem('userInfo');
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUser(parsedUser);
    }
  }, []);

  useEffect(() => {
    if (user && user.userId) {
      fetchWatchlist(user.userId);
    }
  }, [user, fetchWatchlist]);

  useEffect(() => {
    const filtered = watchlist.filter(company =>
      company.companyName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      company.primaryIndustry.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const sorted = [...filtered].sort((a, b) => {
      const dateA = new Date(a.dateAdded);
      const dateB = new Date(b.dateAdded);
      return sortOrder === "desc" ? dateB - dateA : dateA - dateB;
    });
    setFilteredWatchlist(sorted);
    setPage(1); // Reset to first page when filter or sort changes
  }, [watchlist, searchTerm, sortOrder]);

  const removeFromWatchlist = async (watchId) => {
    try {
      await fetch(deleteWatchListITEMURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: user.userId,
          watchId: watchId,
        }),
      });
      await fetchWatchlist(user.userId);
    } catch (error) {
      console.error("Error removing from watchlist: ", error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const pageCount = Math.ceil(filteredWatchlist.length / itemsPerPage);
  const displayedCompanies = filteredWatchlist.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );
  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          My Watchlist
        </Typography>
        {watchlist.length === 0 ? (
          <Paper elevation={3} sx={{ p: 3, mt: 2, backgroundColor: '#f5f5f5' }}>
            <Box display="flex" alignItems="center" mb={2}>
              <InfoOutlined color="primary" sx={{ mr: 1 }} />
              <Typography variant="h6" component="div">
                Your Watchlist is Empty
              </Typography>
            </Box>
            <Typography variant="body1" paragraph>
              You haven't added any companies to your watchlist yet. To get started:
            </Typography>
            <Typography component="ol" sx={{ pl: 3 }}>
              <li>Go to the <Link to="/">Home Page for TOP Employers</Link></li>
              <li>Browse through the companies or search for specific ones</li>
              <li>Click the "Add to Watchlist" button on any company card to add it to your watchlist</li>
            </Typography>
            <Typography variant="body1" mt={2}>
              Once you've added companies, they'll appear here for easy access!
            </Typography>
          </Paper>
        ) : (
          <>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <TextField
                label="Search"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: <Search color="action" />,
                }}
              />
              <FormControl variant="outlined" style={{ minWidth: 120 }}>
                <InputLabel>Sort by Date</InputLabel>
                <Select
                  value={sortOrder}
                  onChange={handleSortChange}
                  label="Sort by Date"
                >
                  <MenuItem value="desc">Newest First</MenuItem>
                  <MenuItem value="asc">Oldest First</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Grid container spacing={3}>
              {displayedCompanies.map((company) => (
                <Grid item xs={12} sm={6} md={4} key={company.watchId}>
                  <Card>
                    <CardContent>
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6" component="div">
                          {company.companyName}
                        </Typography>
                        <Tooltip title="Remove from Watchlist">
                          <IconButton onClick={() => removeFromWatchlist(company.watchId)}>
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <Typography color="text.secondary">
                        Size: {company.companySize}+
                      </Typography>
                      <Typography color="text.secondary">
                        Industry: {company.primaryIndustry}
                      </Typography>
                      <Typography color="text.secondary">
                        Added: {new Date(company.dateAdded).toLocaleDateString()}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      {company.careersPage && (
                        <Button
                          size="small"
                          color="primary"
                          href={company.careersPage}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Career Page
                        </Button>
                      )}
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <Box display="flex" justifyContent="center" mt={4}>
              <Pagination 
                count={pageCount} 
                page={page} 
                onChange={handlePageChange}
                color="primary"
              />
            </Box>
          </>
        )}
      </Box>
    </Container>
  );
}

export default WatchList;